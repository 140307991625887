import React, { useEffect, useState } from 'react';
import './HtmlEditor.css';
import { useHtmlEditor } from 'src/Utils/Hooks/FirmwareRelease/HtmlEditor/useHtmlEditor';
import { IReleaseEntry } from 'src/Utils/Hooks/FirmwareRelease/useFirmwareRelease';
import { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import {
  IReleaseNotesRequest,
  ReleaseNotesType,
  useReleaseNotesUpload,
} from 'src/Utils/Hooks/FirmwareRelease/ReleaseNotes/useReleaseNotesUpload';
import { Editor } from '@tinymce/tinymce-react';

export interface IHtmlEditorParams {
    releaseEntry: IReleaseEntry;
    deviceType: string;
    updateList: (list: IReleaseEntry[]) => void;
    updateEditReleaseLoader: (load: boolean) => void;
    setHtmlEditorInfo: (msg: string, msgType: InfoMessageType) => void;
    isEditor: boolean
  }
const HtmlEditor: React.FC<IHtmlEditorParams> = ({
    releaseEntry,
    deviceType,
    updateList,
    updateEditReleaseLoader,
    setHtmlEditorInfo,
    isEditor
  }) => {
    const {
        getHtmlContent,
        htmlEditorContent,
        htmlEditorContentError,
        htmlEditorContentLoading,
      } = useHtmlEditor();
            const { fileUploadError, fileUploadLoading, uploadReleaseNotes } =
     useReleaseNotesUpload();
      const [editorHtml, setEditorHtml] = useState<string>('');
      useEffect(() => {
        updateEditReleaseLoader(fileUploadLoading || htmlEditorContentLoading);
      }, [fileUploadLoading, updateEditReleaseLoader, htmlEditorContentLoading]);
      useEffect(() => {
        if (htmlEditorContentError !== '') {
          setHtmlEditorInfo(htmlEditorContentError, InfoMessageType.error);
        }
        if (fileUploadError !== '') {
          setHtmlEditorInfo(fileUploadError, InfoMessageType.error);
        }
      }, [htmlEditorContentError, fileUploadError, setHtmlEditorInfo]);
      useEffect(() => {
        if (
          releaseEntry.changelog_url !== undefined &&
          releaseEntry.changelog_url !== ''
        ) {
          getHtmlContent(releaseEntry.version, deviceType);
        }
      }, [getHtmlContent, releaseEntry.version, releaseEntry.changelog_url , deviceType]);
      const handleEditorChange = (html: string) => {
        setEditorHtml(html);
      };
      useEffect(() => {
        if (htmlEditorContent !== '') {
          setEditorHtml(htmlEditorContent);
        }
      }, [htmlEditorContent]);

      const handleImageUpload = (callback: any, value: any, meta: any) => {
        // Provide file and text for the link dialog
        if (meta.filetype === 'file') {
          callback('mypage.html', {text: 'My text'});
        }
    
        // Provide image and alt text for the image dialog
        if (meta.filetype === 'image') {
          callback('myimage.jpg', {alt: 'My alt text'});
        }
    
        // Provide alternative source and posted for the media dialog
        if (meta.filetype === 'media') {
          callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
        }
      }
      const onUpload = () => {
      
        const customStyles = `
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');
           body {
              background-color: black; /* Change this to your desired color */
              font-family: Roboto;
              font-size: 15px;
              color: white;
              margin: 50px 180px 50;
               }
             h1, h2 {
                font-family: Roboto; /* Specify the font family */
                font-size: 24px; /* Default size for headings, adjust as needed */
                font-weight: 300;
                color: rgb(126, 126, 126);
                text-transform: uppercase;
                }
        </style>
      `;
    
      // Combine custom styles with editor content
      const styledHtml = `${customStyles}${editorHtml}`;

        let request: IReleaseNotesRequest = {
          release: releaseEntry,
          releaseType: deviceType,
          releaseNotesType: ReleaseNotesType.HtmlEditor,
          html: styledHtml,
        };
        console.log("Test" + styledHtml);
        uploadReleaseNotes(request);
      };
      
return (
    <>
     <div className="row" style={{ width: '55%' }} >
     <Editor
  apiKey="krdj2lpx7imf1mowx5p75ta28jhosbq19rhq5eu0wkxnqz8l"
  value={editorHtml}
  onEditorChange={handleEditorChange}
  init={{
    height: 500,
    menubar: false,
    plugins: [
      'lists',
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
    'undo redo | styles fontsize | backcolor fontfamily | bullist numlist outdent indent | ' +
    'removeformat | help | image | formatselect',
    content_style:
      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    file_picker_callback: handleImageUpload,
    init_instance_callback: function (htmlEditor) {
      let tinyMceNotificationTox = document.querySelector(
        '.tox .tox-notification--in'
      ) as HTMLElement;
      if (
        tinyMceNotificationTox?.textContent?.includes(
          'This domain is not registered with Tiny Cloud.'
        )
      ) {
        tinyMceNotificationTox!.style!.display = 'none';
      }
    },
    formats: {
      h1: { block: 'h1' },
      h2: { block: 'h2' },
    },
  }}
/>

      <div className="col-sm-12" style={{paddingTop:'2vh'}}>
          <button
             data-testid="save-html"
             className="btn-prim"
             style={{ float: 'left' }}
             onClick={() => {
               onUpload();
             }}
             disabled={ !isEditor}
           >
             Save
           </button>
         </div>
     </div>
    </>
)
}
export default HtmlEditor;
